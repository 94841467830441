import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

// export const HOST_API = process.env.REACT_APP_HOST_API;
export const HOST_API = "https://afya-101.herokuapp.com";
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const FIREBASE_API = {
  apiKey: "AIzaSyDcFehbbTdOmW_0wDV3TvgmyDPYHmMDE4Y",
  authDomain: "afya101-97171.firebaseapp.com",
  projectId: "afya101-97171",
  storageBucket: "afya101-97171.appspot.com",
  messagingSenderId: "270804709531",
  appId: "1:270804709531:web:f187301c70b7e15e32d63e",
};

const app = initializeApp(FIREBASE_API);
export const storage = getStorage(app);

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
